import React from "react";
import UsersTable from "../../components/UsersRequestTable/UsersRequestTable";
import './index.css';


function NewUserRequestsList() {
	return (
		<>
			<div className="usersPageWrapper">
        <UsersTable />
			</div>
		</>
	)
}

export default NewUserRequestsList;