import React from "react";
import RegRequestsTable from "../../components/RegRequestsTable/RegRequestsTable";
import './index.css';

function RequestsPage() {

  return (
    <>
        <div className="RequestsPageWrapper">
          <RegRequestsTable />
        </div>
    </>
  );
}

export default RequestsPage;