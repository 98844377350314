import moment from 'moment';
import DirectoryStore from '../store/DirectoryStore';
import { Button, Input, Checkbox, DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const getFiltersInColumn = (
  data,
  setSelectedKeys,
  selectedKeys,
  confirm,
  nameColumn,
  nameInStore,
  columnDate,
  dateRange,
  notRangeInFilter
) => {
  let rowValues = [];

  if (columnDate) {
    rowValues = [
      ...new Set(
        data?.map((item) => {
          if (item[nameColumn]) {
            if (dateRange) {
              const date = item[nameColumn].split(',');
              return `${moment(date[0]).format('MM-DD-YYYY')} / ${moment(
                date[1]
              ).format('MM-DD-YYYY')}`;
            } else {
              return moment(item[nameColumn]).format('MM-DD-YYYY');
            }
          } else {
            return 'нет данных';
          }
        })
      ),
    ].map((item) => {
      return { label: item, value: item };
    });
  } else if (nameInStore) {
    rowValues = DirectoryStore[nameInStore]?.map((item) => {
      return { label: item.name, value: item.id };
    });
  } else {
    rowValues = [
      ...new Set(
        data?.map((item) => {
          if (item[nameColumn]) {
            return item[nameColumn];
          } else {
            return 'нет данных';
          }
        })
      ),
    ].map((item) => {
      return { label: item, value: item };
    });
  }

  const onChangeFilter = (checked) => {
    setSelectedKeys(checked);
  };

  const onCheckAll = (e) => {
    if (e.target.checked) {
      const allValues = rowValues.map((item) => item.value);
      setSelectedKeys(allValues);
    } else {
      setSelectedKeys();
    }
  };

  const searchFilter = (e) => {
    const searchValues = rowValues.map((item) => {
      if (
        e.target.value &&
        item.label
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase())
      ) {
        return item.value;
      }
    });
    setSelectedKeys(searchValues);
  };

  const getFilter = () => {
    confirm();
  };

  const resetFilter = () => {
    setSelectedKeys();
  };

  const getRangeDate = (e) => {
    if (e) {
      const arrDate = data.map((item) => {
        return moment(item[nameColumn]).format('MM-DD-YYYY');
      });

      let newArrDate = [];
      arrDate.map((item) => {
        if (
          item <= moment(e[1]._d).format('MM-DD-YYYY') &&
          item >= moment(e[0]._d).format('MM-DD-YYYY')
        ) {
          newArrDate.push(item);
        }
      });

      setSelectedKeys(newArrDate);
    }
  };

  return (
    <div className="filterInColumn__wrapper">
      {columnDate && !notRangeInFilter && (
        <div className="RangePickerWrapper">
          <RangePicker
            className="RangePicker"
            onChange={(e) => getRangeDate(e)}
          />
        </div>
      )}
      <Input
        className="filterInColumn__input"
        placeholder="Поиск в фильтре"
        onChange={searchFilter}
      ></Input>
      <Checkbox className="CheckAllChange" onChange={onCheckAll}>
        Check all
      </Checkbox>
      <Checkbox.Group
        className="filterInColumn__checkboxGroup"
        options={rowValues}
        value={selectedKeys}
        // defaultValue={userfields}
        onChange={onChangeFilter}
      />
      <div className="filterInColumn__btns">
        <Button
          className="filterInColumn__btn"
          type="link"
          danger
          size="small"
          onClick={resetFilter}
        >
          Reset
        </Button>
        <Button
          className="filterInColumn__btn"
          type="primary"
          size="small"
          onClick={getFilter}
        >
          Ok
        </Button>
      </div>
    </div>
  );
};

export default getFiltersInColumn;
