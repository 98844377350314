import React from 'react';
import './index.css';
import { Layout } from 'antd';
import DefaultHeader from './DefaultHeader';

const { Content } = Layout;

const DefaultLayout = (props) => {

  return (
    <Layout>
      <DefaultHeader />

      <Content>{props.children}</Content>
    </Layout>
  );
};

export default DefaultLayout;
