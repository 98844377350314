import io from "socket.io-client";

let socketLocal = null;
export function connectSocket() {
  const userData = localStorage.getItem('userData')
  if (!socketLocal && userData) {
    socketLocal = io({
      query: {
        userId: JSON.parse(userData).userId
      },
      transports: ['websocket', 'pooling'],
    });
    console.log('connected socket')
  }
}

export function on(key, cb) {
  if (socketLocal){
    socketLocal.on(key, cb);
  }
}

export function emit(key, arg) {
  if (socketLocal){
    socketLocal.emit(key, arg);
  }
}

export function removeAllListeners(key) {
  if (socketLocal){
    socketLocal.removeAllListeners(key);
  }
}