import './index.css'
import GeoData from "../../components/GeoData/GeoData";

export default () => {
  return (
      <>
        <div className="GeoDataPageWrapper">
        <GeoData />
        </div>
      </>
  )
}