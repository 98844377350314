import React from "react";
import './index.css'
import DeviceOfflainTable from "../../components/DeviceOfflineTable/DeviceOfflineTable";

function deviceOffline() {
    
    
    return (
        <>
          <div className="deviceOfflinePageWrapepr">
            <DeviceOfflainTable />
          </div>
        </>
    )
    
    
}

export default deviceOffline;