import React, {useMemo, useState, useEffect, useRef, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {
  Button,
  Table,
  Form,
  message,
  Input,
  Modal,
  Space,
  Select,
  Spin,
  Checkbox,
  Dropdown,
  FloatButton,
  Popconfirm,
  notification,
  Segmented,
  Badge,
  Tooltip
} from 'antd';
import debounce from 'lodash/debounce';
import {
  DeleteOutlined,
  EditOutlined,
  DownOutlined,
  SyncOutlined,
  UnlockOutlined,
  RollbackOutlined,
  UserAddOutlined,
  PlaySquareOutlined,
  TabletOutlined,
  AimOutlined,
  ControlOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import './index.css';
import { useHttp } from '../../hooks/http.hook';
import SearchField from '../SearchField/SearchField';
import { on, removeAllListeners } from '../../socket';
import GenerateNewIdModal from '../GenerateNewIdModal/GenerateNewIdModal';
import CurrentUserModal from "../CurrentUserModal/CurrentUserModal";
import TrustUserGroup from "../TrustUserGroup/TrustUserGroup";
import dayjs from 'dayjs';

const ForwarderTable = () => {
  const { request } = useHttp();
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [updatedData, setUpdatedData] = useState();
  const [nameBtn, setNameBtn] = useState(null);
  const [clickRowId, setClickRowId] = useState();
  const [searcher, setSearcher] = useState(false);
  const [loading, setLoading] = useState(false);
  const userInfo = useSelector((state) => state.userInfo.userState);
  const isAdmin = useSelector(state => state.userInfo.isAdmin);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(10);
  const [value, setValue] = useState();

  const [actualSegment, setActualSegment] = useState('actual');
  const [isGenerateIdOpen, setGenerateIdOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef(null);

  useEffect(() => {
    const onKeypress = e => {
      if (e.key === 'Enter') {
        searchRef.current.focus({
          cursor: 'all',
        });
      }
    };
    document.addEventListener('keypress', onKeypress);

    return () => {
      document.removeEventListener('keypress', onKeypress);
    };
  }, [])

  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [actionModalText, setActionModalText] = useState('')
  const [handleActionFunction, setHandleActionFunction] = useState(() => () => {})
  const [trustUserModalOpen, setTrustUserModalOpen] = useState(false)
  const [trustUserGroupData, setTrustUserGroupData] = useState({})
  const [currentDeviceType, setCurrentDeviceType] = useState('');

  const actualActionItems = [
    {
      key: 'translationRequest',
      label: 'Запрос трансляции',
    },
    {
      key: 'trustUserGroup',
      label: 'Доступ к просмотру',
    },
    {
      key: 'clearLogin',
      label: 'Очистить пароль',
    },
    {
      key: 'deviceType',
      label: 'Тип устройства',
      children: [
        {
          key: 'tablet',
          label: 'Планшет',
          disabled: currentDeviceType === 'tablet'
        },
        {
          key: 'panel',
          label: 'Пульт',
          disabled: currentDeviceType === 'panel'
        },
        {
          key: 'router',
          label: 'Маршрутизатор',
          disabled: currentDeviceType === 'router'
        }
      ],
    },
    {
      key: 'blockUnblockDevice',
      label: "Заблокировать",
    },
    {
      key: 'deleteDevice',
      label: 'Удалить',
    }
  ];

  const blockedActionItems = [
    {
      key: 'blockUnblockDevice',
      label: 'Разблокировать',
    },
    {
      key: "deleteDevice",
      label: 'Удалить',
    }
  ];

  useEffect(() => {
    on('updateDeviceList', (device) => {
      changeStatusDevice(data, device);
    })

    return function cleanup() {
      removeAllListeners('updateDeviceList');
    }
  }, [data]);

  const changeStatusDevice = (array, itemToChange) => {
    if (array.some(item => item.id === itemToChange.id)) {
      setData(array.map((item) => item.id === itemToChange.id ? {
        ...item,
        connectionStatus: itemToChange.connectionStatus
      } : item))
    }
  }

  const handleMenuClick = async (e, id) => {
    const resData = await request(`/api/device`, `PATCH`, {
      id: id,
      type: e.key
    });

    if (resData) {
      await fetchListDevices(actualSegment);
    }
  }

  const handleActionsMenuClick = async (e, record) => {
    switch (e.key) {
      case 'translationRequest':
        setActionModalText(`Запросить трансляцию ${record.device_id}?`)
        setHandleActionFunction(() => () => {
          if (record.connectionStatus) {
            sendTranslationRequest(record.device_id)
          } else {
            openNotification('Запрос трансляции', `Устройство ${record.device_id} не в сети!`);
          }
        })
        setIsActionModalOpen(true)
        break

      case 'trustUserGroup':
        setTrustUserGroupData({
          device_id: record.device_id,
          readOnlySubs: record.readOnlySubs
        })
        showTrustUserModal();
        break

      case 'clearLogin':
        setActionModalText(`Очистить пароль для ${record.device_id}?`)
        setHandleActionFunction(() => () => clearLogin(record.id))
        setIsActionModalOpen(true)
        break

      case 'router':
      case 'panel':
      case 'tablet':
        handleMenuClick(e, record.id)
        break

      case 'blockUnblockDevice':
        setActionModalText(`${actualSegment === 'actual' ? "Заблокировать" : "Разблокировать"} устройство ${record.call_sign || record.device_id}?`)
        setHandleActionFunction(() => () => actualSegment === 'actual' ?
          blockUnblockDevice(record, '/api/device/block_device')
          : blockUnblockDevice(record, '/api/device/unblock_device'))
        setIsActionModalOpen(true)
        break
        
      case 'deleteDevice':
        setActionModalText(`Удалить устройство ${record.device_id}?`)
        setHandleActionFunction(() => async () => {
          softDelete(record.id);
          setValue('')
          await fetchListDevices(actualSegment)
        })
        setIsActionModalOpen(true)
        break
    }
  }

  const fetchListUsersTable = async () => {
    try {
      const resData = await request(`/api/users/getList`);
      return setUsersData(resData
        .map((el, i) => ({...el, key: i + 1}))
        .filter((el) => el.id !== userInfo.userId))
    } catch (error) {
      console.log('error-fetchDataChartPage >>>', error);
    }
  };

  const showTrustUserModal = async () => {
    await fetchListUsersTable();
    setTrustUserModalOpen(true);
  }

  const columns = isMobile ? [
      {
        title: 'Android id',
        dataIndex: 'device_id',
        key: 'device_id',
        render: (text, record) => {
          return record.device_id ? (
            <div>
              <Badge dot={record.connectionStatus} color='green'>
                <Checkbox checked={record.password}>{record.device_id}</Checkbox>
              </Badge>
            </div>
          ) : (
            <Badge dot={record.connectionStatus} color='green'>
              <Checkbox checked={record.password}>Отсутствует</Checkbox>
            </Badge>
          )
        },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
          return (
            <div style={{display: 'block', textAlign: 'center', left: '50%', top: '50%'}}>
              {actualSegment === 'deleted' ? <Popconfirm
                  title='Вернуть устройства в актуальные?'
                  description={`Вы уверены что хотите вернуть устройство ${record.call_sign || record.device_id}?`}
                  onConfirm={() => revertDevice(record)}
                  okText="Подтвердить"
                  cancelText="Отмена">
                  <Button
                    type='primary'
                    icon={<RollbackOutlined/>}
                  ></Button>
                </Popconfirm> :
                <ul style={{padding: 0}}>
                  <li style={{display: 'block', marginBottom: '1em'}}><LocalizedModal id={record.id} size='small'/></li>
                  {actualSegment === 'actual' ?
                    <>
                      <li style={{display: 'block', marginBottom: '1em'}}><Button
                        style={{width: '100%', position: 'relative'}}
                        type='default'
                        onClick={() => {
                          clearLogin(record.id);
                        }}
                        size='small'
                      >
                        Очистить пароль
                      </Button>
                      </li>
                      <li style={{display: 'block', marginBottom: '1em'}}>
                        <TrustUserGroup
                          device_id={record.device_id}
                          readOnlySubs={record.readOnlySubs}
                          fetchListDevices={fetchListDevices}
                          actualSegment={actualSegment}
                          userInfo={userInfo}
                          isMobile={isMobile}
                          trustUserModalOpen={trustUserModalOpen}
                          setTrustUserModalOpen={setTrustUserModalOpen}
                        />
                      </li>
                    </> : null}
                  <li style={{display: 'block', marginBottom: '1em'}}><FeatureModal id={record.id}
                                                                                    device_id={record.device_id}
                                                                                    password={record.password}
                                                                                    call_sign={record.call_sign}
                                                                                    army_post={record.army_post}
                                                                                    type={record.type}
                                                                                    tg={record.tg}/></li>
                  <li style={{display: 'block', marginBottom: '1em'}}>
                    <BlockDeviceModalMobile id={record.id}
                                            device_id={record.device_id}
                                            password={record.password}
                                            call_sign={record.call_sign}
                                            army_post={record.army_post}
                                            type={record.type}
                                            tg={record.tg}>
                    </BlockDeviceModalMobile>
                  </li>
                </ul>}
            </div>
          )
        },
      },
    ]
    :
    [
      {
        title: 'Android id',
        dataIndex: 'device_id',
        key: 'device_id',
        width: 150,
        render: (text, record) => {
          switch (record.type) {
            case 'tablet':
              return (
                <>
                  <Tooltip title="Планшет">
                    <TabletOutlined style={{fontSize: '18px', color: ( record.connectionStatus ? '#4acc0e' 
                      : ((actualSegment === 'actual' && '#a3a3a3') || (actualSegment === 'block' && 'red'))
                      ), paddingRight:'4px' }}/>
                  </Tooltip>
                  {text}
                </>
              );
            case 'panel':
              return (
                <>
                  <Tooltip title="Пульт">
                    <AimOutlined style={{fontSize: '18px', color: ( record.connectionStatus ? '#4acc0e' 
                      : ((actualSegment === 'actual' && '#a3a3a3') || (actualSegment === 'block' && 'red'))
                      ), paddingRight:'4px' }}/>
                  </Tooltip>
                  {text}
                </>
              );
            case 'router':
              return (
                <>
                  <Tooltip title="Маршрутизатор">
                    <ControlOutlined style={{fontSize: '18px', color: ( record.connectionStatus ? '#4acc0e' 
                      : ((actualSegment === 'actual' && '#a3a3a3') || (actualSegment === 'block' && 'red'))
                      ), paddingRight:'4px' }}/>
                  </Tooltip>
                  {text}
                </>
              );
          }
        },
      },
      {
        title: 'Позывной',
        dataIndex: 'call_sign',
        key: 'call_sign',
        width: 150,
        render: (text, record) => {
          return (
            <>
              {clickRowId === record.id &&
                'call_sign' === nameBtn ? (
                <>
                  <Form.Item name={'call_sign'}>
                    <Input
                      className="tableInput"
                      ref={(input) => input && input.focus()}
                      onBlur={() => {
                        setClickRowId();
                        setNameBtn();
                      }}
                      defaultValue={record.call_sign}
                      value={text}
                      style={{ textAlign: 'center' }}
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <div
                    style={{
                      height: '25px',
                    }}
                    onClick={() => {
                      setClickRowId(record.id);
                      setNameBtn('call_sign');
                    }}
                  >{text}</div>
                </>
              )}
            </>
          );
        },
      },
      {
        title: 'Военная часть',
        dataIndex: 'army_post',
        key: 'army_post',
        width: 150,
        render: (text, record) => {
          return (
            <>
              {clickRowId === record.id &&
                'army_post' === nameBtn ? (
                <Form.Item name={'army_post'}>
                  <Input
                    className="tableInput"
                    ref={(input) => input && input.focus()}
                    onBlur={() => {
                      setClickRowId();
                      setNameBtn();
                    }}
                    defaultValue={record.army_post}
                    value={text}
                    style={{ textAlign: 'center' }}
                  />
                </Form.Item>
              ) : (
                <>
                  <div
                    style={{
                      height: '25px',
                    }}
                    onClick={() => {
                      setClickRowId(record.id);
                      setNameBtn('army_post');
                    }}
                  >{text}</div>
                </>
              )}
            </>
          );
        },
      },
      {
        title: 'Пользователь',
        dataIndex: 'actualUser',
        key: 'actualUser',
        width: 150,
        render: (text, record) => {
          return (
            <>
              <Space>
                {record['user.id'] || record?.user?.id ?
                  record['user.name'] || record?.user?.name
                  ||
                  record['user.email'] || record?.user?.email : 'Отсутствует'}
                {actualSegment === 'actual' &&
                  <>
                    <CurrentUserModal
                      id={record.id}
                      fetchListDevices={fetchListDevices}
                      actualSegment={actualSegment}
                      DebounceSelect={DebounceSelect}
                      fetchUserList={fetchUserList}
                    />
                  </>}
              </Space>
            </>
          );
        }
      },
      {
        title: 'Телеграм',
        dataIndex: 'tg',
        key: 'tg',
        width: 150,
        render: (text, record) => {
          return (
            <div style={{textAlign: 'center'}}>
              {clickRowId === record.id &&
                'tg' === nameBtn ? (
                <Form.Item name={'tg'}>
                  <Input
                    className="tableInput"
                    ref={(input) => input && input.focus()}
                    onBlur={() => {
                      setClickRowId();
                      setNameBtn();
                    }}
                    defaultValue={record.tg}
                    value={text}
                    style={{ textAlign: 'center' }}
                  />
                </Form.Item>
              ) : (
                <>
                  <div
                    style={{
                      height: '25px',
                    }}
                    onClick={() => {
                      setClickRowId(record.id);
                      setNameBtn('tg');
                    }}
                  ><a href={`https://t.me/${text}`} target={'_blank'}>{text}</a></div>
                </>
              )}
            </div>
          );
        }
      },
      {
        title: 'Лицензия до',
        dataIndex: 'license_expires',
        key: 'license_expires',
        width: 150,
        render: (text, record) => {
          if (record['license_store.license_expires']) {
            return dayjs(record['license_store.license_expires']).format('DD.MM.YYYY HH:mm:ss')
          } else if (record?.license_store?.license_expires) {
            return dayjs(record?.license_store?.license_expires).format('DD.MM.YYYY HH:mm:ss')
          } else {
            return 'Отсутствует'
          }
        }
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: '28px',
        fixed: 'right',
        render: (text, record) => {
          return (
            <Space>
              {actualSegment === 'actual' &&
                <Dropdown
                  menu={{
                    items: actualActionItems,
                    onClick: async (e) => await handleActionsMenuClick(e, record),
                    onOpenChange: () => setCurrentDeviceType(record.type)
                  }}
                  trigger={['click']}>
                  <Button style={{padding: 0, margin: 0, display: 'inline-block', width: '30px', height: '30px' }}>
                    <MoreOutlined style={{fontSize: '20px', paddingTop: '2px' }}/>
                  </Button>
                </Dropdown>}

              {actualSegment == 'block' && 
                <Dropdown
                  menu={{
                    items: blockedActionItems,
                    onClick: async (e) => await handleActionsMenuClick(e, record)
                  }}
                  trigger={['click']}>
                  <Button onClick={(e) => e.preventDefault()} style={{padding: 0, margin: 0, display: 'inline-block', width: '30px', height: '30px' }}>
                    <MoreOutlined style={{fontSize: '20px', paddingTop: '2px' }}/>
                  </Button>
                </Dropdown>}
            </Space>
          )
        },
      },
    ].filter((item) => !((item.title === 'Тип устройства' && actualSegment === 'block') || (item.title === 'Тип устройства' && actualSegment === 'deleted')));

  const sendTranslationRequest = async (device_id) => {
    try {
      request('/api/device/sendTranslationRequest', 'POST', {
        device_id: device_id,
        initiator: userInfo.email,
      })
        .then((res) => {
          if (res.error) {
            openNotification('Запрос трансляции', 'Ошибка при отправке запроса на начало трансляции');
          } else {
            openNotification('Запрос трансляции', `Запрос на трансляцию с устройства ${device_id} успешно отправлен!`);
          }
        })
        .catch(() => console.log(`Ошибка запроса`))
    } catch (error) {
      console.log('error sendTranslationRequest >>>', error);
      openNotification('Запрос трансляции', 'Ошибка при отправке запроса на начало трансляции');
    }
  }

  const fetchListDevices = useCallback(async (segment) => {
    try {
      setLoading(true);
      request(searcher ? `/api/device/find_with/${value}?actualSegment=${actualSegment}&offset=${page * 10}` :
        `/api/device/${segment}/${isAdmin ? 0 : userInfo.userId}?offset=${page * 10}`)
        .then(resData => {
          setTotalData(resData.count);
          setData(resData.rows?.length ? resData.rows.map((el, i) => ({ ...el, key: i + 1 })) : []);
        })
        .finally(() => setLoading(false))
    } catch (error) {
      console.log('error-fetchDataForwarder >>>', error)
    }
  }, [isAdmin, page, request, userInfo, searcher])

  useEffect(() => {
    if (!searcher) {
      fetchListDevices(actualSegment);
    }
  }, [actualSegment, fetchListDevices, searcher]);

  useEffect(() => {
    if (updatedData) {
      const newData = data.current?.map((item) => {
        if (item.id === updatedData.id) {
          return {...item, [updatedData.nameKey]: updatedData.values};
        } else {
          return item;
        }
      });
      setData(newData);
      setUpdatedData(null);
    }
  }, [updatedData]);

  const CurrentUserModal = (params) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [value, setValue] = useState();

    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = async () => {
      setIsModalOpen(false);
      try {
        if (value.label && value.key) {
          const response = await request('/api/device/changeUser', 'POST', {
            userInfo: value,
            deviceId: params.id,
          });

          if (!response.error) {
            message.success('Пользователь успешно добавлен');
          } else {
            message.warning(response.message)
          }

          await fetchListDevices(actualSegment);
        } else {
          message.warning('Вы не указали пользователя ☹️');
        }
      } catch (error) {
        console.log('Validate Failed:', error);
      }
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    return (
      <>
        <Button type="dashed" style={{ position: 'absolute', right: '1px', top: '0px' }} onClick={showModal}>
          <EditOutlined />
        </Button>
        <Modal title="Изменение пользователя"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}>
          <p>Выберите пользователя для этого устройства.</p>
          <DebounceSelect
            value={value}
            placeholder="Выберите пользователя"
            fetchOptions={fetchUserList}
            onChange={(newValue) => {
              setValue(newValue)
            }}
            style={{
              width: '100%',
            }}
          />
        </Modal>

      </>
    );
  };

  const BlockDeviceModalMobile = (params) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = async () => {
      actualSegment === 'actual' ?
        blockUnblockDevice(params, '/api/device/block_device')
        : blockUnblockDevice(params, '/api/device/unblock_device')
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    return (
      <>
        <Button
          type='default'
          onClick={showModal}
          icon={<UnlockOutlined/>}
          size='small'
          style={{width: '100%', position: 'relative'}}
          danger>
          {actualSegment === 'actual' ? 'Заблокировать' : 'Разблокировать'}
        </Button>
        <Modal title={`Информация об устройстве`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <p>{`Вы уверены что хотите ${actualSegment === 'actual' ? 'заблокировать' : 'разблокировать'} устройство ${params.device_id}`}</p>
        </Modal>
      </>
    );
  }

  const FeatureModal = (params) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    return (
      <>
        <Button type="default" onClick={showModal} size='small' style={{width: '100%', position: 'relative'}}
                disabled={params.device_id ? false : true}>
          Свойства
        </Button>
        <Modal title={`Информация об устройстве ${params.device_id}`} open={isModalOpen} onOk={handleOk}
               onCancel={handleCancel}>
          <p>Информация об устройстве</p>
          <p>{`id: ${params.id}`}</p>
          <p>{`Android id: ${params.device_id ? params.device_id : 'Отсутствует'}`}</p>
          <p>{`Пароль: ${params.password ? params.password : 'Отсутствует'}`}</p>
          <p>{`Позывной: ${params.call_sign ? params.call_sign : 'Отсутствует'}`}</p>
          <p>{`Военная часть: ${params.army_post ? params.army_post : 'Отсутствует'}`}</p>
          <p>{`Тип устройства: ${params.type ? params.type : 'Отсутствует'}`}</p>
          <p>{`Телеграмм: ${params.tg ? params.tg : 'Отсутствует'}`}</p>
        </Modal>
      </>
    );
  }

  const LocalizedModal = (params) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
      softDelete(params.id);
      setValue('')
      fetchListDevices(actualSegment)
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    return (
      <>
        <Button type="primary" onClick={showModal} style={{width: '100%', position: 'relative'}} danger>
          <DeleteOutlined/>
        </Button>
        <Modal title="Подтвердите действие" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <p>Вы действительно хотите удалить это устройство?</p>
        </Modal>
      </>
    );
  };

  const ActionModal = ({text, callback}) => {
    const handleOk = () => {
      setIsActionModalOpen(false);
      callback()
      
    };
    const handleCancel = () => {
      setIsActionModalOpen(false);
    };
    return (
        <Modal title={text} open={isActionModalOpen} onOk={handleOk} onCancel={handleCancel}></Modal>
    );
  };

  const DebounceSelect = ({fetchOptions, debounceTimeout = 500, ...props}) => {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
      const loadOptions = (value) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setFetching(true);
        if (value) {
          fetchOptions(value).then((newOptions) => {
            if (fetchId !== fetchRef.current) {
              // for fetch callback order
              return;
            }
            setOptions(newOptions);
          });
          setFetching(false);
        }
      };
      return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
      <Select
        labelInValue
        filterOption={false}
        showSearch
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small"/> : null}
        {...props}
        options={options}
      />
    );
  }

  const openNotification = (title, text) => {
    api.info({
      message: <b>{title}</b>,
      description: text,
      placement: 'topRight',
    })
  }

  const blockUnblockDevice = async (params, url) => {
    const response = await request(url, 'POST', {device_id: params.device_id})
    if (response) openNotification('Блокировка устройства', response);
    await fetchListDevices(actualSegment);
  }

  const revertDevice = async (params) => {
    const response = await request('/api/device/revert_device', 'POST', {device_id: params.device_id});
    openNotification('Актуализация устройства', response);
    await fetchListDevices(actualSegment);
  }

  const fetchUserList = async (username) => {
    const userData = localStorage.getItem('userData');
    let token = null;
    if (userData) {
      token = JSON.parse(userData).token;
    }
    let headers = {Authorization: token};
    return fetch(`/api/users/getList?name=${username}`, {
      headers,
    })
      .then((response) => response.json())
      .then((body) =>
        body.map((user) => ({
          label: user.name || user.email,
          value: user.id,
        })),
      );
  }

  const softDelete = async (id) => {
    try {

      const resData = await request(`/api/device/delete/${id}`, `POST`);

      if (resData) {
        await fetchListDevices(actualSegment)
      }

    } catch (error) {
      console.log('Ошибка внутри softDelete -> ', error);
      message.error('Ошибка при удалении устройства!');
    }
  }

  const clearLogin = async (id) => {
    try {
      const resData = await request(`/api/device/clear`, 'POST', {
        id: id
      });

      if (resData) {
        await fetchListDevices(actualSegment);
      }
    } catch (error) {
      console.log('ERROR - onFinishModal - forwarder>>>', error);
      if (error.message === 'Validation error') {
        message.info('ОШИБКА создания! Такой агент уже существует.');
      }
    }
  };

  const onFinish = async (values) => {
    try {
      await request(`/api/device`, 'PATCH', {
        id: clickRowId,
        device_id: values['ddd'],
        call_sign: values['call_sign'],
        army_post: values['army_post'],
        password: values['password'],
        tg: values['tg'],
      });

      await fetchListDevices(actualSegment);
      setClickRowId();
      setNameBtn();
      form.resetFields();
    } catch (error) {
      console.log('error-getDeleteCell >>>', error, error.message);
    }
  };

  return (
    <>
      {contextHolder}
      <div className="ForwarderTable__header">
        <ActionModal text={actionModalText} callback={() => handleActionFunction()}/>
        {isMobile || <TrustUserGroup
                  device_id={trustUserGroupData.device_id ? trustUserGroupData.device_id : ''}
                  readOnlySubs={trustUserGroupData.readOnlySubs ? trustUserGroupData.readOnlySubs : null}
                  fetchListDevices={fetchListDevices}
                  actualSegment={actualSegment}
                  userInfo={userInfo}
                  trustUserModalOpen={trustUserModalOpen}
                  setTrustUserModalOpen={setTrustUserModalOpen}
                  showTrustUserModal={showTrustUserModal}
                  usersData={usersData}
                />}
        <SearchField
          setValue={setValue}
          setTotalData={setTotalData}
          setData={setData}
          setSearcher={setSearcher}
          actualSegment={actualSegment}
          fetchList={fetchListDevices}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setGenerateIdOpen={setGenerateIdOpen}
          searchRef={searchRef}/>
        <GenerateNewIdModal
          fetchListDevices={fetchListDevices}
          actualSegment={actualSegment}
          userInfo={userInfo}
          DebounceSelect={DebounceSelect}
          fetchUserList={fetchUserList}
          isGenerateIdOpen={isGenerateIdOpen}
          setGenerateIdOpen={setGenerateIdOpen}
          searchValue={searchValue} 
          setSearchValue={setSearchValue} />
      </div>
      {isAdmin && <Segmented block defaultValue={'actual'} options={[
        {
          label: 'Актуальные',
          value: 'actual'
        },
        {
          label: 'Заблокированные',
          value: 'block'
        },
        {
          label: 'Удаленные',
          value: 'deleted'
        }
      ]} onChange={(segment) => {
        setActualSegment(segment);
      }}/>}
      <Form form={form} onFinish={onFinish}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={{
            position: ['bottomCenter'],
            showSizeChanger: false,
            current: page,
            pageSize: pageSize,
            total: totalData,
            onChange: async (page, pageSize) => {
              setLoading(true);
              setPage(page);
              setPageSize(pageSize);
              request(searcher ? `/api/device/find_with/${value}?actualSegment=${actualSegment}&offset=${page * 10}`
              :
                `/api/device/${actualSegment}/${isAdmin ? 0 : userInfo.userId}?offset=${page * 10}`)
                .then((res) => {
                  setData(res.rows.map((el, i) => ({ ...el, key: i + 1 })));
                })
                .finally(() => setLoading(false))
            },
          }}
          scroll={isMobile ? null : {
            x: 2000,
          }}
          bordered
        />
      </Form>
      {searcher ? <FloatButton
        icon={<SyncOutlined />}
        tooltip={`Очистить фильтр`}
        onClick={() => {
          setSearcher(false)
          setPage(1)
        }}/> : null}
    </>
  );
};

export default ForwarderTable;
