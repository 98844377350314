import './index.css';
import React from 'react';


import ForwarderTable from '../../components/forwarderTable/ForwarderTable';


function DirectoryPage() {
 

  return (
    <>
        <div className="DirectoryPageWrapper">
        <ForwarderTable />
    
        </div>
    </>
  );
}

export default DirectoryPage;
