import { useEffect, useRef, useCallback, useState } from 'react';
import useStateWithCallback from './useStateWithCallback';
import { on, emit, removeAllListeners } from '../socket';
import { useSelector } from 'react-redux';
import ACTIONS from '../utils/socketActions';

export const LOCAL_VIDEO = 'LOCAL_VIDEO';


export default function useWebRTC(roomID) {
  const userInfo = useSelector((state) => state.userInfo.userState);
  const [clients, updateClients] = useStateWithCallback([]);
  const [isVideoEnabled, setVideoEnabled] = useState(true);
  const [isAudioEnabled, setAudioEnabled] = useState(true);
  const [isScreenSharing, setScreenSharing] = useState(false);

  const addNewClient = useCallback((newClient, cb) => {
    updateClients(list => {
      if (!list.some(c => c.peerID === newClient.peerID)) {
        return [...list, newClient];
      }
      return list;
    }, cb);

  }, [clients, updateClients]);

  const peerConnections = useRef({});
  const localMediaStream = useRef(null);
  const screenMediaStream = useRef(null);
  const peerMediaElements = useRef({
    [LOCAL_VIDEO]: null
  });

  useEffect(() => {
    async function handleNewPeer({ peerID, createOffer }) {
      if (peerID in peerConnections.current) {
        return console.warn(`Already connected to peer ${peerID}`);
      }
      const response = await fetch('/api/turnInfo/getCredentials', {
        method: 'GET',
        headers: {
          'apikey': process.env.REACT_APP_TURN_SECRET
        }
      });
      const jsonRes = await response.json();
      peerConnections.current[peerID] = new RTCPeerConnection({
        iceServers: [{
          urls: 'stun:turn.stormapi.su',
          username: jsonRes.username
        }]
      });

      peerConnections.current[peerID].onicecandidate = event => {
        if (event.candidate) {
          emit(ACTIONS.RELAY_ICE, {
            peerID,
            iceCandidate: event.candidate
          });
        }
      };

      let tracksNumber = 0;
      peerConnections.current[peerID].ontrack = ({ streams: [remoteStream] }) => {
        tracksNumber++;

        if (tracksNumber === 2) {
          tracksNumber = 0;
          const state = clientObject(peerID, isVideoEnabled);
          addNewClient(state, () => {
            if (peerMediaElements.current[state.peerID]) {
              peerMediaElements.current[state.peerID].srcObject = remoteStream;
            } else {
              // FIX LONG RENDER IN CASE OF MANY CLIENTS
              let settled = false;
              const interval = setInterval(() => {
                if (peerMediaElements.current[state.peerID]) {
                  peerMediaElements.current[state.peerID].srcObject = remoteStream;
                  settled = true;
                }

                if (settled) {
                  clearInterval(interval);
                }
              }, 1000);
            }
          });
        }
      };

      localMediaStream.current.getTracks().forEach(track => {
        peerConnections.current[peerID].addTrack(track, localMediaStream.current);
      });

      if (createOffer) {
        const offer = await peerConnections.current[peerID].createOffer();

        await peerConnections.current[peerID].setLocalDescription(offer);

        emit(ACTIONS.RELAY_SDP, {
          peerID,
          sessionDescription: offer
        });
      }
    }

    on(ACTIONS.ADD_PEER, handleNewPeer);

    return () => {
      removeAllListeners(ACTIONS.ADD_PEER);
    };
  }, []);

  useEffect(() => {
    on(ACTIONS.TOGGLE_VIDEO, ({ peerID, isVideoEnabled }) => {
      updateClients(clients.map(i => {
        if (i.peerID === peerID) {
          return { peerID: peerID, isVideoEnabled: isVideoEnabled };
        }
        return i;
      }));
    });


    return () => {
      removeAllListeners(ACTIONS.TOGGLE_VIDEO);
    };
  }, [clients, updateClients]);

  useEffect(() => {
    async function setRemoteMedia({ peerID, sessionDescription: remoteDescription }) {
      await peerConnections.current[peerID]?.setRemoteDescription(
        new RTCSessionDescription(remoteDescription)
      );

      if (remoteDescription.type === 'offer') {
        const answer = await peerConnections.current[peerID].createAnswer();
        await peerConnections.current[peerID].setLocalDescription(answer);

        emit(ACTIONS.RELAY_SDP, {
          peerID,
          sessionDescription: answer
        });
      }
    }

    on(ACTIONS.SESSION_DESCRIPTION, setRemoteMedia);

    return () => {
      removeAllListeners(ACTIONS.SESSION_DESCRIPTION);
    };
  }, []);

  useEffect(() => {
    on(ACTIONS.ICE_CANDIDATE, ({ peerID, iceCandidate }) => {
      peerConnections.current[peerID]?.addIceCandidate(
        new RTCIceCandidate(iceCandidate)
      );
    });

    return () => {
      removeAllListeners(ACTIONS.ICE_CANDIDATE);
    };
  }, []);

  useEffect(() => {
    const handleRemovePeer = ({ peerID }) => {
      if (peerConnections.current[peerID]) {
        peerConnections.current[peerID].close();
      }

      delete peerConnections.current[peerID];
      delete peerMediaElements.current[peerID];

      updateClients(list => list.filter(c => c.peerID !== peerID));
    };

    on(ACTIONS.REMOVE_PEER, handleRemovePeer);

    return () => {
      removeAllListeners(ACTIONS.REMOVE_PEER);
    };
  }, []);

  useEffect(() => {
    // Обновление видеопотока для локального пользователя
    if (peerMediaElements.current[LOCAL_VIDEO]) {
      if (isVideoEnabled) {
        peerMediaElements.current[LOCAL_VIDEO].srcObject = localMediaStream.current;
      } else {
        peerMediaElements.current[LOCAL_VIDEO].srcObject = null;
      }
    }
  }, [isVideoEnabled]);

  useEffect(() => {
    // Обновление видеопотоков для всех удаленных участников
    clients.forEach(client => {
      if (client.peerID !== LOCAL_VIDEO && peerMediaElements.current[client.peerID]) {
        const videoElement = peerMediaElements.current[client.peerID];

        if (client.isVideoEnabled) {
          const remoteStream = peerConnections.current[client.peerID]?.getRemoteStreams?.()[0];
          if (remoteStream) {
            videoElement.srcObject = remoteStream;
          } else {
            console.warn(`No remote stream available for client ${client.peerID}`);
          }
        } else {
          videoElement.srcObject = null;
        }
      }
    });
  }, [clients]);


  useEffect(() => {
    async function startCapture() {
      localMediaStream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          facingMode: { ideal: 'environment' },
          width: 1280,
          height: 720
        }
      });
      const state = clientObject(LOCAL_VIDEO, isVideoEnabled);
      addNewClient(state, () => {
        const localVideoElement = peerMediaElements.current[LOCAL_VIDEO];

        if (localVideoElement) {
          localVideoElement.volume = 0;
          localVideoElement.srcObject = localMediaStream.current;
        }
      });
    }

    startCapture()
      .then(() => emit(ACTIONS.JOIN,
        {
          room: roomID,
          userId: userInfo.userId,
          callState: { peerID: Object.keys(peerConnections.current)[0], videoState: true, audioState: true }
        }))
      .catch(e => console.error('Error getting userMedia:', e));

    return () => {
      localMediaStream.current.getTracks().forEach(track => track.stop());
      if (screenMediaStream.current) {
        screenMediaStream.current.getTracks().forEach(track => track.stop());
      }

      emit(ACTIONS.LEAVE);
    };
  }, [roomID]);

  const clientObject = (peerID, isVideoEnabled) => ({ peerID: peerID, isVideoEnabled: isVideoEnabled });

  const provideMediaRef = useCallback((id, node) => {
    peerMediaElements.current[id] = node;
  }, []);

  const toggleVideo = useCallback(() => {
    localMediaStream.current.getVideoTracks().forEach(track => {
      const isEnabled = !track.enabled;
      track.enabled = isEnabled;
      updateClients(list =>
        list.map(client => ({
          ...client,
          isVideoEnabled: client.peerID === LOCAL_VIDEO ? isEnabled : client.isVideoEnabled
        }))
      );


      setVideoEnabled(isEnabled);

      // Notify other peers about video state change
      emit(ACTIONS.TOGGLE_VIDEO, {
        peerID: Object.keys(peerConnections.current)[0],
        isVideoEnabled: isEnabled,
        userId: userInfo.userId,
        roomID: roomID
      });

    });
  }, [localMediaStream, updateClients]);

  const toggleAudio = useCallback(() => {
    localMediaStream.current.getAudioTracks().forEach(track => {
      track.enabled = !track.enabled;
      setAudioEnabled(track.enabled);
    });
  }, []);

  const getVideoDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.filter(device => device.kind === 'videoinput');
  }

  const switchCamera = async () => {
    try {
      const videoDevices = await getVideoDevices();
      const currentDeviceId = localMediaStream.current.getVideoTracks()[0].getSettings().deviceId;
      let nextDeviceIndex = videoDevices.findIndex(device => device.deviceId === currentDeviceId) + 1;

      if (nextDeviceIndex >= videoDevices.length) {
        nextDeviceIndex = 0;
      }

      // Получаем новый видеопоток, сохраняя текущее аудио устройство
      const newVideoStream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: videoDevices[nextDeviceIndex].deviceId },
        audio: localMediaStream.current.getAudioTracks().length > 0
          ? { deviceId: localMediaStream.current.getAudioTracks()[0].getSettings().deviceId }
          : true
      });

      // Заменяем видеотрек в текущем соединении
      const videoTrack = newVideoStream.getVideoTracks()[0];
      const sender = peerConnections.current[Object.keys(peerConnections.current)[0]]
        ?.getSenders()
        .find(s => s.track.kind === 'video');

      if (sender) {
        await sender.replaceTrack(videoTrack);
      } else {
        console.warn('Видео-сендер не найден');
      }

      // Обновляем локальный видеопоток
      localMediaStream.current = newVideoStream;
      const localVideoElement = peerMediaElements.current[LOCAL_VIDEO];
      if (localVideoElement) {
        localVideoElement.srcObject = newVideoStream;
      } else {
        console.warn('Элемент для локального видео не найден');
      }
    } catch (error) {
      console.error('Ошибка при переключении камеры:', error);
    }
  }

  const toggleScreenSharing = useCallback(async () => {
    if (isScreenSharing) {
      const videoTrack = localMediaStream.current.getVideoTracks()[0];
      replaceTrack(screenMediaStream.current, videoTrack);
      screenMediaStream.current.getTracks().forEach(track => track.stop());
      setScreenSharing(false);
    } else {
      try {
        screenMediaStream.current = await navigator.mediaDevices.getDisplayMedia({
          video: true
        });
        const screenTrack = screenMediaStream.current.getVideoTracks()[0];
        replaceTrack(localMediaStream.current, screenTrack);
        setScreenSharing(true);
      } catch (e) {
        console.error('Error sharing screen:', e);
      }
    }
  }, [isScreenSharing]);

  const replaceTrack = (oldStream, newTrack) => {
    oldStream.getVideoTracks()[0].stop();
    for (const peerID in peerConnections.current) {
      const sender = peerConnections.current[peerID].getSenders().find(s => s.track.kind === 'video');
      sender.replaceTrack(newTrack);
    }
  };

  return {
    clients,
    provideMediaRef,
    toggleVideo,
    toggleAudio,
    switchCamera,
    toggleScreenSharing,
    isVideoEnabled,
    isAudioEnabled,
    isScreenSharing,
  };
}