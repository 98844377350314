export const LOGIN_ROUTE = '/login';
export const MAIN_ROUTE = '/';
export const DIRECTORY_ROUTE = '/directory/:directoryName';
export const OFFLINE_DIRECTORY_ROUTE = '/device_offline';
export const CHART_ROUTE = '/app';
export const MAPS_ROUTE = '/map';
export const STATISTICS = '/statistics';
export const GEO = '/geo';
export const CALL_PAGE = '/call_page';
export const ROOM_PAGE = '/room/:id';
export const MESSAGES_ROUTE = '/messages';
export const REG_REQUESTS_ROUTE = '/registered';
export const TRANSLATION = '/translation';
export const USERS = '/users';
export const USERS_REQUESTS = '/users_requests';
export const GROUP_TRANSLATIONS = '/group_translations';

// Стандартные размеры экрана bootstrap
export const SCREEN_SM = 576;
export const SCREEN_MD = 768;
export const SCREEN_LG = 992;
export const SCREEN_XL = 1200;
export const SCREEN_XXL = 1400;

// Размеры для трансляций
export const HEIGHT_VALUES = {
  1: '90vh',
  2: '90vh',
  3: '43vh',
  4: '43vh',
  5: '43vh',
  6: '43vh',
  7: '27vh',
  8: '27vh',
  9: '27vh',
};
export const SPAN_CHANGER = {
  1: 24,
  2: 12,
  3: 12,
  4: 12,
  5: 8,
  6: 8,
  7: 8,
  8: 8,
  9: 8,
}
